<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="setting" />
				<span>系统管理</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 角色管理 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form">
				<a-row :gutter="24">
					<a-col :span="6">
						<a-form-item :label="`角色名称`">
							<a-input placeholder="请输入设备编号" v-model="searchData.role_name" />
						</a-form-item>
					</a-col>
					<a-col :span="6" :style="{
					  textAlign: 'left',
					  paddingLeft: '25px',
					  marginTop: '6px',
					}">
						<a-button type="primary" @click="search" html-type="submit" class="">
							查询
						</a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">角色列表</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showAddEdit('add')">
							新增
						</a-button>
						<a-button type="primary" class="marginr20" @click="showAddEdit('edit')">
							详情
						</a-button>
						<a-button type="primary" class="marginr20" @click="deleteRole">
							删除
						</a-button>
						<a-button type="primary" class="marginr20" @click="authoritySetting">
							角色权限
						</a-button>
					</div>
				</div>

				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" :total="total_page * 10"
				 :data-source="roleList" rowKey="role_id" size="small">

					<span slot="status" slot-scope="status">
						<a-tag color="red" v-if="status == 0">
							暂停
						</a-tag>
						<a-tag color="blue" v-if="status == 1">
							启动
						</a-tag>
					</span>
				</a-table>
			</div>
		</div>

		<a-modal v-model="authorityShow" title="设置角色权限" width="1000px" :footer="null">
			<a-spin :spinning="detailLoading">
				<p style="width: 90%;margin: 0 auto; margin-bottom: 20px;font-size: 16px;font-weight: 700;">角色名称： {{info.role_name}}</p>
				<div class="tes roleee" >
					<div class="table-th">
						<div>一级权限</div>
						<div>二级权限</div>
					</div>
					<div class="taes">
						<div class="contet">
							<div class="chebox" v-for="(item, index) in authorityList"  :key="index">
								<div class="chebox-item">
									<a-checkbox :checked="item.check" @change="onChangeCheck(index, item.check)">
										{{item.router_name}}
									</a-checkbox>
								</div>
							</div>
						</div>
						<div class="right">
							<div class="chebox" v-for="(item, index) in authorityList" :key="index">
								<div class="chebox-item" >
									<a-checkbox-group v-model="item.checks" :options="item.list" > <span slot="label" slot-scope="{ value }">{{ value }}</span> </a-checkbox-group>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div class="model-footer">
					<div class="left">
						<a-button type="primary" class="marginr20" html-type="submit" @click="setAuthorityDetail()">
							保存
						</a-button>
						<a-button class="marginr20" html-type="submit" @click="authorityShow = false">
							取消
						</a-button>
					</div>
					<div class="right" :key="editIndex">
						<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev', 'au')" class="" html-type="submit">
							上一条
						</a-button>
						<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next', 'au')" class="marginr20" html-type="submit">
							下一条
						</a-button>
					</div>
				</div>
			</a-spin>
		</a-modal>

		<a-modal v-model="addEditShow" :title="isAdd ? '新增角色' : '编辑角色'" :footer="null">
			<a-form-model ref="ruleForm" :model="info" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" style="margin: 0 auto; width: 80%">
				<a-row :gutter="24">
					<a-col :span="24">
						<a-form-model-item label="角色名称：" prop="role_name">
							<a-input v-model="info.role_name" />
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="描述信息：">
							<a-radio-group v-model="info.status">
								<a-radio :value="0">
									暂停
								</a-radio>
								<a-radio :value="1">
									启动
								</a-radio>
							</a-radio-group>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
			<div class="model-footer">
				<div class="content-btns" v-if="isAdd">
					<a-button type="primary" class="marginr20" html-type="submit" @click="addRole()">
						保存
					</a-button>
					<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="left" v-if="!isAdd">
					<a-button type="primary" class="marginr20" html-type="submit" @click="updateRole">
						修改
					</a-button>
					<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="right" v-if="!isAdd" :key="editIndex">
					<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
						上一条
					</a-button>
					<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
						下一条
					</a-button>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import moment from "moment";


	const columns = [{
			title: "角色ID",
			dataIndex: "role_id",
			width: 200
		},
		{
			title: "角色名称",
			dataIndex: "role_name",
			width: 300
		},
		{
			title: "状态",
			dataIndex: "status",
			scopedSlots: {
				customRender: 'status'
			},
		},
	];

	import http from '../http'
	export default {
		data() {
			return {
				columns,
				// 编辑与添加
				addEditShow: false,
				// 是否添加状态
				isAdd: false,
				// 搜索
				searchData: {
					page: 1,
					role_name: ''
				},
				// 角色列表
				roleList: [],
				// 多少条数据 * 10
				total_page: 0,

				// 添加与编辑的数据
				info: {
					role_name: '',
					status: 1
				},
				rules: {
					role_name: [{
						required: true,
						message: '请输入角色名称',
						trigger: 'blur'
					}, ],
				},
				// 表格选择的数据, 存储的是id
				selectedRowKeys: [],
				// 存储的是数据
				selectedRowDataList: [],
				// 当前是第几条编辑信息
				editIndex: 0,

				authorityShow: false,
				authorityList: [],
				detailLoading: false
			};
		},
		computed: {
			count() {
				return this.expand ? 11 : 7;
			},
		},
		updated() {
			console.log("updated");
		},
		mounted() {
			this.getRoleList()
		},
		methods: {
			moment,
			handleSearch(e) {
				e.preventDefault();
				this.form.validateFields((error, values) => {
					console.log("error", error);
					console.log("Received values of form: ", values);
				});
			},

			handleReset() {
				this.searchData = {
					page: 1,
					role_name: ''
				}
				this.getRoleList()
			},

			toggle() {
				this.expand = !this.expand;
			},

			handleChange() {},

			handleSelectChange(value) {
				console.log(value);
				this.form.setFieldsValue({
					note: `Hi, ${value === "male" ? "man" : "lady"}!`,
				});
			},
			// 点击上一条 或者 下一条、
			editToData(type, fn) {
				let index = -1
				if (type == 'prev') {
					index = this.editIndex - 1
					if (index == 0) {
						index = 0
					}
				} else if (type == 'next') {
					index = this.editIndex + 1
				}
				this.editIndex = index
				if (fn) {
					this.getAuthorityDetail()
				} else {
					this.info = this.selectedRowDataList[this.editIndex]
				}
			},
			// 判断是否可以点击上一条 或者 下一条、
			isEditBtn(type) {
				if (type == 'prev') {
					if (this.editIndex == 0) {
						return true
					}
					if (this.selectedRowDataList == 1) {
						return true
					}
					if (this.editIndex >= 1) {
						return false
					}
				} else if (type == 'next') {
					if ((this.selectedRowDataList.length - 1) == this.editIndex) {
						return true
					}
				}
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				console.log('selectedRowKeys changed: ', selectedRowKeys);
				this.selectedRowKeys = selectedRowKeys;
			},
			// 显示 添加与编辑 的 弹窗
			showAddEdit(type) {
				if (type == 'add') {
					// 添加初始数据
					this.info = {
						role_name: '',
						status: 1
					}
					this.isAdd = true
				} else if (type == 'edit') {
					// 判断是否已经选择数据
					if (this.selectedRowKeys.length == 0) {
						this.$message.warning('请先选择角色');
						return
					}

					let selectDatas = []
					this.roleList.forEach((v) => {
						if (this.selectedRowKeys.includes(v.role_id)) {
							selectDatas.push(v)
						}
					})
					this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
					this.editIndex = 0
					this.info = this.selectedRowDataList[0]
					this.isAdd = false
				}

				this.addEditShow = true
			},
			// 搜索
			search() {
				this.searchData.page = 1
				this.getRoleList()
			},
			// 角色列表
			getRoleList() {
				http({
					method: 'post',
					url: '/api.admin.role.list/',
					data: this.searchData
				}).then((res) => {
					this.roleList = res.data.list
					this.total_page = res.data.total_page
				})
			},
			// 
			deleteRole() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择角色');
					return
				}

				this.$confirm({
					title: '是否删除选中角色?',
					content: '删除角色可能会导致部分用户无法登录，确定删除吗？',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						let selectDatas = []
						this.roleList.forEach((v) => {
							if (this.selectedRowKeys.includes(v.role_id)) {
								selectDatas.push(v.role_id)
							}
						})
						http({
							method: 'post',
							url: '/api.admin.role.del/',
							data: {
								role_id: selectDatas.join(),
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('删除角色成功');
								this.getRoleList()
							} else {
								this.$message.error(res.data);
							}
						})
					}
				});


			},
			// 添加角色
			addRole(type) {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						http({
							method: 'post',
							url: '/api.admin.role.add/',
							data: {
								role_id: 0,
								...this.info
							}
						}).then((res) => {
							if (res.code == '200') {
								if (!type) {
									this.addEditShow = false
								}
								this.$message.success(type == 'edit' ? '修改角色信息成功' : '添加角色成功');
								this.getRoleList()
							} else {
								this.$message.error(res.data);
							}
						})
					} else {
						this.$message.warning('请检查是否已经输入必填项!');
						return false;
					}
				});
			},
			// 更新角色
			updateRole() {
				this.addRole('edit')
			},
			// 权限设置
			authoritySetting() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择角色');
					return
				}

				let selectDatas = []
				this.roleList.forEach((v) => {
					if (this.selectedRowKeys.includes(v.role_id)) {
						selectDatas.push(v)
					}
				})
				this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
				this.editIndex = 0
				this.authorityShow = true
				this.getAuthorityDetail()
			},
			getAuthorityDetail() {
				this.detailLoading = true
				http({
					method: 'post',
					url: '/api.admin.authority.list/',
					data: {
						role_id: this.selectedRowDataList[this.editIndex].role_id,
					}
				}).then((res) => {
					if (res.code == '200') {
						this.info = this.selectedRowDataList[this.editIndex]
						this.authorityList = res.data.map((v) => {
							let checks = []
							if (v.list) {
								v.list.forEach((vi) => {
									if (vi.is_able == 1) {
										checks.push(vi.parent_id+','+vi.router_id)
									}
								})
							}
							return {
								...v,
								check: v.is_able == 0 ? false : true,
								list: v.list ? v.list.map((vi) => {
									return {
										value: vi.parent_id+','+vi.router_id,
										label: vi.router_name
									}
								}) : [],
								checks: checks
							}
						})
						this.detailLoading = false
					} else {
						this.$message.error(res.message);
					}
				})
			},
			setAuthorityDetail () {
				let checks = []
				console.log(this.authorityList)
				this.authorityList.forEach((v) => {
					if (v.check) {
						checks.push({
							"router_id": v.router_id,
							"parent_id": v.parent_id,
						})
					}
					v.checks.forEach((vi) => {
						let ids = vi.split(",")
						checks.push({
							"router_id": ids[1],
							"parent_id": ids[0],
						})
					})
				})
				http({
					method: 'post',
					url: '/api.admin.authority.add/',
					data: {
						role_id: this.selectedRowDataList[this.editIndex].role_id,
						authority: JSON.stringify(checks)
					}
				}).then((res) => {
					if (res.code == '200') {
						this.$message.success('修改角色权限成功');
						this.getRoleList()
					} else {
						this.$message.error(res.message);
					}
				})
			},
			onChangeCheck (index, check) {
				this.authorityList[index].check = !check
				if (this.authorityList[index].check) {
					let checks = []
					this.authorityList[index].list.forEach((v) => {
						checks.push(v.value)
					})
					this.authorityList[index].checks = checks
				} else {
					this.authorityList[index].checks = []
				}
			}
		},
	};
</script>
<style lang="less">
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}

	.roleee.tes {
		width: 90%;
		margin: 0 auto;
		border: 1px solid #e8e8e8;
		border-radius: 3px;

		.table-th {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #e8e8e8;
			height: 30px;

			div {
				width: 22%;

				&:nth-child(2) {
					width: 78%;
				}

				box-sizing: border-box;
				height: 30px;
				padding-left: 20px;
				font-weight: 700;
				border-right: 1px solid #e8e8e8;
				display: flex;
				align-items: center;

				&:last-child {
					border-right: none;
				}
			}
		}

		.taes {
			display: flex;
			align-items: stretch !important;

			&>div {
				width: 22%;

				&:nth-child(2) {
					width: 78%;
				}
			}

			.chebox {
				overflow: hidden;
				box-sizing: border-box;
				height: 90px !important;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #e8e8e8;

				&:last-child {
					border-bottom: none;
				}

				.chebox-item {
					box-sizing: border-box;
					padding-left: 20px;
				}
			}

			.left {
				height: 300px;
				border-right: 1px solid #e8e8e8;

				.chebox {
					height: 100%;
				}
			}

			.contet {
				border-right: 1px solid #e8e8e8;
				height: auto !important; 
			}

			.right {
				height: 300px;
			}
		}
	}
</style>
